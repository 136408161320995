import React, { useState } from 'react';
import { Form, Button, Alert, ProgressBar } from 'react-bootstrap';
import axios from '../axiosConfig';
import { useNavigate } from 'react-router-dom';

const ConstrucaoNovaForm = ({ onSuccess }) => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    nomeObjeto: '',
    oficio: null,
    matricula: null,
    programaNecessidades: null,
    plantaTopografica: null,
    relatorioFotografico: null,
    origemRecurso: '',
    valor: '',
    enderecoObra: '',
    nomenclaturaObjeto: '',
    esgoto: 'Sim',
    aguaPotavel: 'Sim',
    plantaEsgoto: null,
    plantaAgua: null,
    infiltracao: null,
    fonteAlimentacao: '',
    declaracao: null,
    ensaioSondagem: null,
    issqn: null
  });

  const [error, setError] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0); // Progresso de upload
  const [isSubmitting, setIsSubmitting] = useState(false);

  const formatCurrency = (value) => {
    const numericValue = value.replace(/\D/g, ''); 
    const formattedValue = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(numericValue / 100); 
    return formattedValue;
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    let newValue = value;
    if (name === 'valor') {
      newValue = formatCurrency(value);
    }
    setFormData({
      ...formData,
      [name]: files ? files[0] : newValue,
    });
  };

  const handleNext = () => {
    if (step === 1 && (!formData.nomeObjeto || !formData.oficio)) {
      setError('Preencha todos os campos obrigatórios');
    } else {
      setError('');
      setStep(step + 1);
    }
  };

  const handlePrevious = () => {
    setStep(step - 1);
  };

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitting(true); // Ativa o estado de envio

    try {
      const data = new FormData();
      for (const key in formData) {
        if (formData[key] !== null && formData[key] !== '') {
          data.append(key, formData[key]);
        }
      }

      const response = await axios.post('/api/protocolos/construcao-nova', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          const percentCompleted = Math.floor((loaded * 100) / total);
          setUploadProgress(percentCompleted); // Atualiza o progresso de upload
        },
      });

      if (response.data && response.data.protocolo && response.data.protocolo._id) {
        const createdProtocoloId = response.data.protocolo._id;
        navigate(`/protocolo/${createdProtocoloId}`);
      } else {
        throw new Error('ID do protocolo não foi retornado');
      }
    } catch (error) {
      console.error('Erro ao criar protocolo:', error);
      setError('Erro ao criar protocolo. Por favor, tente novamente.');
    } finally {
      setIsSubmitting(false); // Desativa o estado de envio
    }
  };

  const renderCivilStep = () => (
    <>
      <h4>Dados Gerais</h4>
      <Form.Group className="mb-3">
        <Form.Label>Nome da Solicitação (Objeto)</Form.Label>
        <Form.Control
          type="text"
          name="nomeObjeto"
          value={formData.nomeObjeto}
          onChange={handleChange}
          required
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Ofício da Solicitação (PDF)</Form.Label>
        <Form.Control
          type="file"
          name="oficio"
          onChange={handleChange}
          required
        />
      </Form.Group>
    </>
  );

  const renderArquiteturaStep = () => (
    <>
      <h4>Informações de Arquitetura e da Obra</h4>
      <Form.Group className="mb-3">
        <Form.Label>Matrícula/Escritura ou Termo de Posse do Terreno</Form.Label>
        <Form.Control
          type="file"
          name="matricula"
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Programa de Necessidades</Form.Label>
        <Form.Control
          type="file"
          name="programaNecessidades"
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Planta Topográfica</Form.Label>
        <Form.Control
          type="file"
          name="plantaTopografica"
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Relatório Fotográfico</Form.Label>
        <Form.Control
          type="file"
          name="relatorioFotografico"
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Origem do Recurso</Form.Label>
        <Form.Control
          type="text"
          name="origemRecurso"
          value={formData.origemRecurso}
          onChange={handleChange}
          placeholder="Caixa, SINFRA, etc."
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Valor em R$</Form.Label>
        <Form.Control
          type="text"
          name="valor"
          value={formData.valor}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Endereço da Obra</Form.Label>
        <Form.Control
          type="text"
          name="enderecoObra"
          value={formData.enderecoObra}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Nomenclatura do Objeto conforme Convênio</Form.Label>
        <Form.Control
          type="text"
          name="nomenclaturaObjeto"
          value={formData.nomenclaturaObjeto}
          onChange={handleChange}
        />
      </Form.Group>
    </>
  );

  const renderHidrossanitariaStep = () => (
    <>
      <h4>Informações Hidrossanitários</h4>
      <Form.Group className="mb-3">
        <Form.Label>Existe rede pública de esgoto?</Form.Label>
        <Form.Control
          as="select"
          name="esgoto"
          value={formData.esgoto}
          onChange={handleChange}
        >
          <option>Sim</option>
          <option>Não</option>
        </Form.Control>
      </Form.Group>
      {formData.esgoto === 'Sim' ? (
        <Form.Group className="mb-3">
          <Form.Label>Anexar planta com a localização</Form.Label>
          <Form.Control
            type="file"
            name="plantaEsgoto"
            onChange={handleChange}
          />
        </Form.Group>
      ) : (
        <>
          <Form.Group className="mb-3">
            <Form.Label>Apresentar teste de infiltração de solo e teste de sondagem</Form.Label>
            <Form.Control
              type="file"
              name="infiltracao"
              onChange={handleChange}
            />
          </Form.Group>
        </>
      )}

      <Form.Group className="mb-3">
        <Form.Label>Existe rede pública de distribuição de água potável?</Form.Label>
        <Form.Control
          as="select"
          name="aguaPotavel"
          value={formData.aguaPotavel}
          onChange={handleChange}
        >
          <option>Sim</option>
          <option>Não</option>
        </Form.Control>
      </Form.Group>
      {formData.aguaPotavel === 'Sim' ? (
        <Form.Group className="mb-3">
          <Form.Label>Anexar planta com a localização</Form.Label>
          <Form.Control
            type="file"
            name="plantaAgua"
            onChange={handleChange}
          />
        </Form.Group>
      ) : (
        <>
          <Form.Group className="mb-3">
            <Form.Label>Descrever a fonte de alimentação de água para a edificação</Form.Label>
            <Form.Control
              type="text"
              name="fonteAlimentacao"
              value={formData.fonteAlimentacao}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Apresentar declaração</Form.Label>
            <Form.Control
              type="file"
              name="declaracao"
              onChange={handleChange}
            />
          </Form.Group>
        </>
      )}
    </>
  );

  const renderEstruturalStep = () => (
    <>
      <h4>Informações Estruturais</h4>
      <Form.Group className="mb-3">
        <Form.Label>Ensaio de Sondagem do terreno (SPT)</Form.Label>
        <Form.Control
          type="file"
          name="ensaioSondagem"
          onChange={handleChange}
        />
      </Form.Group>
    </>
  );

  const renderOrcamentoStep = () => (
    <>
      <h4>Informações de Orçamento</h4>
      <Form.Group className="mb-3">
        <Form.Label>Declaração de ISSQN com alíquota e base de cálculo</Form.Label>
        <Form.Control
          type="file"
          name="issqn"
          onChange={handleChange}
        />
      </Form.Group>
    </>
  );

  return (
    <Form onSubmit={handleSubmit}>
      {error && <Alert variant="danger">{error}</Alert>}
      {uploadProgress > 0 && uploadProgress < 100 && (
        <ProgressBar now={uploadProgress} label={`${uploadProgress}%`} className="mb-3" />
      )}
      {step === 1 && renderCivilStep()}
      {step === 2 && renderArquiteturaStep()}
      {step === 3 && renderHidrossanitariaStep()}
      {step === 4 && renderEstruturalStep()}
      {step === 5 && renderOrcamentoStep()}

      <div className="d-flex justify-content-between mt-4">
        {step > 1 && (
          <Button variant="secondary" onClick={handlePrevious}>
            Voltar
          </Button>
        )}
        {step < 5 && (
          <Button variant="primary" onClick={handleNext}>
            Próxima Fase
          </Button>
        )}
        {step === 5 && (
          <Button variant="primary" type="submit" disabled={isSubmitting}>
            {isSubmitting ? 'Enviando...' : 'Enviar Solicitação'}
          </Button>
        )}
      </div>
    </Form>
  );
};

export default ConstrucaoNovaForm;